import { useState } from 'react';
import ApplicationLogo from '@/Components/ApplicationLogo';
import Dropdown from '@/Components/Dropdown';
import NavLink from '@/Components/NavLink';
import ResponsiveNavLink from '@/Components/ResponsiveNavLink';
import { Link } from '@inertiajs/react';

export default function Authenticated({ user, header, children }) {
    const [showingNavigationDropdown, setShowingNavigationDropdown] = useState(false);

    return (
        <div id="container-screen">
            <nav className="">
                <div className="content">
                    <div className="flex justify-between h-16">
                        <div className="flex">
                            <div id="title">
                                <img src="/storage/icon-white.png" />
                                Reading Order
                            </div>

                            <div className="hidden space-x-8 sm:-my-px sm:ml-10 sm:flex">
                                {user ?
                                    <NavLink href={route('dashboard')} active={route().current('dashboard')}>
                                        Home
                                    </NavLink>
                                    :
                                    <NavLink href={route('welcome')} active={route().current('welcome')}>
                                        Home
                                    </NavLink>
                                }
                            </div>
                            <div className="hidden space-x-8 sm:-my-px sm:ml-10 sm:flex">
                                <NavLink href={route('series')} active={route().current('series')}>
                                    Lists
                                </NavLink>
                            </div>
                            <div className="hidden space-x-8 sm:-my-px sm:ml-10 sm:flex">
                                <NavLink href={route('book')} active={route().current('book')}>
                                    Books
                                </NavLink>
                            </div>
                            {user?.id == 1 &&
                                <div className="hidden space-x-8 sm:-my-px sm:ml-10 sm:flex">
                                    <NavLink href={route('admin')} active={route().current('admin')}>
                                        Admin
                                    </NavLink>
                                </div>
                            }
                            <div className="hidden space-x-8 sm:-my-px sm:ml-10 sm:flex">
                                <NavLink href={route('privacy')} active={route().current('privacy')}>
                                    Privacy Policy
                                </NavLink>
                            </div>
                        </div>

                    {user
                        ?
                        <div className="hidden sm:flex sm:items-center sm:ml-6">
                            <div className="ml-3 relative">
                                <Dropdown>
                                    <Dropdown.Trigger>
                                        <span className="inline-flex rounded-md">
                                            <button
                                                type="button"
                                                className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md focus:outline-none transition ease-in-out duration-150"
                                            >
                                                {user.name}

                                                <svg
                                                    className="ml-2 -mr-0.5 h-4 w-4"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                        clipRule="evenodd"
                                                    />
                                                </svg>
                                            </button>
                                        </span>
                                    </Dropdown.Trigger>

                                    <Dropdown.Content>
                                        <Dropdown.Link href={route('profile.edit')}>Profile</Dropdown.Link>
                                        <Dropdown.Link href={route('logout')} method="post" as="button">
                                            Log Out
                                        </Dropdown.Link>
                                    </Dropdown.Content>
                                </Dropdown>
                            </div>
                        </div>
                        :

                        <div className="hidden sm:flex sm:items-center sm:ml-6">
                            <div className="ml-3 relative">
                                <Link
                                    href={route('login')}
                                    className="font-semibold dark:hover:text-white focus:outline focus:outline-2 focus:rounded-sm focus:outline-red-500"
                                >
                                    Log in
                                </Link>

                                <Link
                                    href={route('register')}
                                    className="ml-4 font-semibold dark:hover:text-white focus:outline focus:outline-2 focus:rounded-sm focus:outline-red-500"
                                >
                                    Register
                                </Link>
                            </div>
                        </div>
                    }

                        <div className="burger -mr-2 flex items-center sm:hidden">
                            <button
                                onClick={() => setShowingNavigationDropdown((previousState) => !previousState)}
                                className="inline-flex items-center justify-center p-2 rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                            >
                                <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                    <path
                                        className={!showingNavigationDropdown ? 'inline-flex' : 'hidden'}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M4 6h16M4 12h16M4 18h16"
                                    />
                                    <path
                                        className={showingNavigationDropdown ? 'inline-flex' : 'hidden'}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>

                <div className={(showingNavigationDropdown ? 'block' : 'hidden') + ' sm:hidden'}>
                    <div className="pt-2 pb-3 space-y-1">
                        {user ?
                            <ResponsiveNavLink href={route('dashboard')} active={route().current('dashboard')}>
                                Home
                            </ResponsiveNavLink>
                            :
                            <ResponsiveNavLink href={route('welcome')} active={route().current('welcome')}>
                                Home
                            </ResponsiveNavLink>
                        }
                        <ResponsiveNavLink href={route('series')} active={route().current('series')}>
                            Series
                        </ResponsiveNavLink>
                        <ResponsiveNavLink href={route('book')} active={route().current('book')}>
                            Book
                        </ResponsiveNavLink>
                    </div>

                {user
                    ?
                    <div className="pt-4 pb-1 border-t border-yellow-100">
                        <div className="px-4">
                            <div className="font-medium text-base text-yellow-700">{user.name}</div>
                            <div className="font-medium text-sm text-yellow-600">{user.email}</div>
                        </div>

                        <div className="mt-3 space-y-1">
                            <ResponsiveNavLink href={route('profile.edit')}>Profile</ResponsiveNavLink>
                            <ResponsiveNavLink method="post" href={route('logout')} as="button">
                                Log Out
                            </ResponsiveNavLink>
                        </div>
                    </div>
                    :

                    <div className="pt-4 pb-1 border-t border-yellow-100">
                        <div className="mt-3 space-y-1">
                            <ResponsiveNavLink
                                href={route('login')}
                                className="font-semibold dark:hover:text-white focus:outline focus:outline-2 focus:rounded-sm focus:outline-red-500"
                            >
                                Log in
                            </ResponsiveNavLink>

                            <ResponsiveNavLink
                                href={route('register')}
                                className="font-semibold dark:hover:text-white focus:outline focus:outline-2 focus:rounded-sm focus:outline-red-500"
                            >
                                Register
                            </ResponsiveNavLink>
                        </div>
                    </div>
                }
                </div>
            </nav>

            {header && (
                <header className="">
                    <div className="content py-6 px-4">{header}</div>
                </header>
            )}

            <main>{children}</main>
        </div>
    );
}
